import React,{useState,useEffect} from 'react'
import './news.css';

const News=() =>{
    const [news,setNews]=useState([])
    useEffect(()=>{
        fetch(`https://lokmacilar.pythonanywhere.com/products/news/`)
        .then(res=>res.json())
        .then(response=>setNews(response))
    },[])
  return (
    <>
    <div className='contain'>

    </div>
    <div className='news-container'>
        <h2 className='news-header'>Haberler</h2>
        {news.map(news=>
            <div key={news.id} className='new'>
              <h2 className='new-caption'>{news.caption}</h2>
              <span className='date'>{news.date}</span>
              <div className='row'>
              <div className='col-1'>
                <img src={news.file} alt={news.caption}/>                     
              </div>
              <div className='col-2'>
                <p>{news.content}
                <a className='new-button' href={`/news/${news.id}/`}>Detay</a>              

                </p>     

              </div>
              </div>
            </div>
            )}
    </div>
    </>

  )
}

export default News