import React from 'react'
import './navbar.css'
import instagram from './instagram.png'
import facebook from './facebook.png'
import youtube from './youtube.png'
import whatsapp from './whatsapp.png'
import phone from './phone.png'
import lokma from './lokma-1.png'
const Navbar=()=> {

    return (
        <>
    <div className='social'>
        <ul>
            <li><img src={phone} alt='phone'/><a href='tel:+905426907712'> Arayınız..</a></li>
        </ul>
        <ul>
        <li><a href='https://www.instagram.com/' target='_blank' rel="noopener noreferrer"><img src={instagram} alt='instagram'/></a></li>
        <li><a href='https://www.youtube.com/' target='_blank' rel="noopener noreferrer"><img src={youtube} alt='youtube'/></a></li>
        <li><a href='https://www.facebook.com/' target='_blank' rel="noopener noreferrer"><img src={facebook} alt='facebook'/></a></li>
        </ul>
    </div>
    
    <nav className='navbar'>
            <div className='logo'>
                 <span><img src={lokma}/></span>
            </div>
            <div className='menu'>
                <ul className='link'>
                    <li className='link'><a href='/'>Anasayfa</a></li>
                    <li className='link'><a href='/products/'>Ürünler</a></li>
                    <li className='link'><a href='/news/'>Haberler</a></li>
                    <li className='link'><a href='/franchise/'>Franchise</a></li>

                </ul>                
            </div>
                
       </nav>

        </>
  )
}

export default Navbar