import React,{useEffect,useState,useContext} from 'react'
import { AuthContext } from '../context/Context';


import './login.css'

const Login = () => {
    const {loginUser} = useContext(AuthContext)
    const {errors} = useContext(AuthContext)      
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [passwordShow, setPasswordShow] = useState(false);
  
    useEffect(() => {
      if (localStorage.getItem('token') !== null) {
      } else {
        setLoading(false);
      }
    }, []);
  
    const togglePassword = () => {
      setPasswordShow(!passwordShow);
    };
  
  
    return (
        <div className='container'>
          {errors === true && <span className='error-message'>Parola veya Kullanıcı Adı Hatalı.</span>}
          {loading === true ?( 
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        ):
            <div>
            <form onSubmit={loginUser} className="login-form">
              <h1>Giriş Yap</h1>
              <div className="textbox">
    
              <input
                type='text'
                value={username}
                name='username'
                placeholder='Kullanıcı Adı'
                required
                onChange={e => setUsername(e.target.value)}
              />
              </div>
              <div className="textbox">
              <input
                type={passwordShow ? "text" : "password"}
                value={password}
                placeholder='Parola'
                name='password'
                required            
                onChange={e => setPassword(e.target.value)}
              />
              </div>
              <span className='password-open'>Parolayı Göster</span> 
              <input type='checkbox' onClick={togglePassword}/>
              <button type='submit'>Giriş</button>
            </form>
          
          </div>}
        </div>
      );
}

export default Login