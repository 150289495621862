import React,{useState} from 'react';
import './franchisecreate.css';

const FranchiseCreate = () => {

const [fullName,setFullName]=useState()  
const [phoneNumber,setPhoneNumber]=useState()  
const [message,setMessage]=useState('Mesaj Yok')  


const HandleSubmit=(e)=>{
    e.preventDefault();
    const data = new FormData();
    data.set('name',fullName)
    data.set('phoneNumber',phoneNumber)
    data.set('message',message)
    fetch(`https://lokmacilar.pythonanywhere.com/products/franchise-create/`,{
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({ name: fullName,
        phoneNumber:phoneNumber,
        message:message })

    }).then(res=>res.json())
    .then(response=>{alert('Talebiniz Alınmıştır.');localStorage.setItem('phoneNumber',phoneNumber)})
    .catch(error=>console.log(error));
}




  return (
    <div className='franchise-container'>      
      <h2 className='franchise-header'>Başvuru Formu</h2>
        <form onSubmit={HandleSubmit}>
            <div className='inputs'>
              <input type='text' placeholder='İsim Soyisim' required onChange={(e)=>setFullName(e.target.value)}/>
            </div>
            <div className='inputs'>
              <input type='text' maxLength={11} minLength={11} placeholder='Telefon Numarası' required onChange={(e)=>setPhoneNumber(e.target.value)}/>              
            </div>
            <div className='inputs'>
              <textarea  placeholder='Mesajınız' onChange={(e)=>setMessage(e.target.value)}/>              
            </div>
            <div className='button'>
              <button type='submit'>Gönder</button>
            </div>
        </form>

</div>
  )
}

export default FranchiseCreate