import React,{useState,useEffect} from "react";
import './products.css';

const Products = () => {
    const [products,setProducts]=useState([])
    const [fullName,setFullName]=useState('')
    const [phoneNumber,setPhoneNumber]=useState()
    const [deamend,setDeamend] = useState()
    
    useEffect(()=>{
        fetch(`https://lokmacilar.pythonanywhere.com/products/products/`)
        .then(res=>res.json())
        .then(response=>setProducts(response))
    },[])

    const HandleSubmit=(id,e)=>{
      e.preventDefault();
      
      const data = new FormData()
      data.set('product',id)
      data.set('fullName',fullName)
      data.set('phoneNumber',phoneNumber)
      data.set('deamend',deamend)
      fetch(`https://lokmacilar.pythonanywhere.com/products/deamend-create/`,{
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({ product: id,
        fullName:fullName,
        phoneNumber:phoneNumber,
        deamend:deamend })

    }).then(res=>res.json())
    .then(response=>{alert('Talebiniz Alınmıştır.');
    localStorage.setItem('productssend','true');
  })
    
    .catch(error=>console.log(error))  
  }

  return (
    <div className="products-container">
        <h2 className="products-header">Özel Günler</h2>
        {products.map(product=>
        <div key={product.id} className="product">
          <h2>{product.caption}</h2>
          <img src={product.image} alt={product.caption}/>
          <div className="products-text">

          <p>{product.persons} Kişilik</p>
          <p>{product.price} TL</p>
          <p>{product.description}</p>
          </div>

          <a href={`/product-detail/${product.id}`}>
          Detay&#x2192;
          </a>
          <div className="deamand-create">
            <h3>Talep Oluştur</h3>
            <form onSubmit={(e)=>HandleSubmit(product.caption,e)}>
              <div className="inputs">
              <input type='text' placeholder="İsim Soyisim" required onChange={(e)=>setFullName(e.target.value)}/>
              <input maxLength='11' type='text' placeholder="Telefon Numarası" required onChange={(e)=>setPhoneNumber(e.target.value)}/>
              </div>
              <div className="inputs">
              <textarea placeholder="Talebiniz" required onChange={(e)=>setDeamend(e.target.value)}/>
              </div>
              <div className="button">
              <button type="submit" >Gönder</button>

              </div>
            </form>

          </div>
        </div>

        )}
        
    </div>
  )
}
export default Products


