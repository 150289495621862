import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import './newsdetail.css';

const NewsDetail = () => {
  const [news,setNew] = useState([]) 
  const {id} = useParams()  
  useEffect(()=>{
    fetch(`https://lokmacilar.pythonanywhere.com/products/news/${id}/`)
    .then(res=>res.json())
    .then(response=>setNew(response))
  },[id])
  return (
    <div className='news-detail-container'>
      <h2>{news.caption}</h2>
      <span className='news-date'>{news.date}</span>

      <div className='file-box'>
      <img src={news.file} alt={news.caption}/>
      </div>
      <div className='news-content'>
          <p>{news.content}</p>
      </div>
    </div>
  )
}

export default NewsDetail