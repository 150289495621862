import React,{useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './signup.css'
const SignUp = () => {
    
    const navigate = useNavigate()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [countries,setCountries] = useState([]);
    const [cities,setCities]= useState([]);
    const [country,setCountry]= useState('');
    const [city,setCity]= useState('');
    const [email,setEmail]= useState('');
    const [countryError, setCountryError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [userError,setUserError] = useState('');
    const [emailError,setEmailError] = useState('');

    useEffect(() => {
        fetch(`https://lokmacilar.pythonanywhere.com/users/country-list/`)
        .then(res => res.json())
        .then(data => {
          const countries = data;
          console.log(countries)
          setCountries(countries);
    })
    }, []);
  
    useEffect(() => {
      fetch(`https://lokmacilar.pythonanywhere.com/users/city-list/`)
      .then(res => res.json())
      .then(data => {
        const cities = data;
        console.log(cities)
        setCities(cities);
        setLoading(false)
  })
  
  }, []);
  
  const handleSubmit = event => {
    event.preventDefault();
    const data = new FormData();
      data.append("username", username);
      data.append("password",password);
      data.append("country",country);
      data.append("city", city);
      data.append("email", email);
  
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json', 
        };
  
      if (password !== password2){
         setError(true)
      }
      if (country === ''){
        setCountryError(true)
     }
     if (city === ''){
      setCityError(true)
      }
  
      else{
  
      
      axios.post(`https://lokmacilar.pythonanywhere.com/users/user-create/`, data,headers)
      .then(response => console.log(JSON.stringify(response),
      localStorage.setItem('username',response.data.username),
      localStorage.setItem('token',response.data.token),
      localStorage.setItem('id',response.data.id),
      navigate(`/`)))
      .catch(error=>{setUserError(error.response.data.usererror);setEmailError(error.response.data.emailerror)}) 
    }
  }
  

  return (
    <div className='register-container'>
      

      {loading === true ? (
      <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      ):
    <div>
    <form onSubmit={handleSubmit} className="register-form">
      <h1>Kayıt Ol</h1>
      <div className="textbox">
        <input
          type='text'
          value={username}
          placeholder='Kullanıcı Adı'
          onChange={e => setUsername(e.target.value.trim())}
          required
        />
        {userError && <p>Bu Kullanıcı Adı Kayıtlı</p>}

        </div>
        <div className="textbox">
        <input
          type='email'
          value={email}
          placeholder='Email Adresi'
          onChange={e => setEmail(e.target.value.trim())}
          required
        />
        {emailError && <p>Bu e-Posta Adresi Kayıtlı</p>}

        </div>

        <div className="textbox">
        <input
          type='password'
          value={password}
          placeholder='Parola'
          onChange={e => setPassword(e.target.value.trim())}
          required
        />
        </div>
        <div className="textbox">
        <input
          type='password'
          value={password2}
          placeholder='Parola Tekrar'
          onChange={e => setPassword2(e.target.value.trim())}
        />
        {error === true && <p>Parola Uyuşmadı.</p>}

        </div>
        <div className="selectbox">        
          <select onChange={e=>setCountry(e.target.value)} value={country}>
            <option>Ülke Seçiniz</option>
            {countries.map(country =>
              <option required name='country' key={country.id} value={country.id}>{country.name}</option>
              )}
          </select>
          {countryError === true && <p>Lütfen Bir Ülke Seçiniz.</p>}
        
        <select onChange={e=>setCity(e.target.value)} value={city}>
         <option>Şehir Seçiniz</option>
          {cities.filter(cities => cities.country == country).map(city =>
        <option required name='city' key={city.id} value={city.id}>{city.name}</option>
        )}
        </select>
        {cityError === true && <p>Lütfen Bir Şehir Seçiniz.</p>}
      </div>
        <button type='submit'>Kayıt Ol</button>        
      </form>

</div>
}    
    </div>

);

}

export default SignUp