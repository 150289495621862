import React,{useEffect,useState} from "react";
import './homepage.css';
import lok from './lok.jpg';
import pide from './pide.png';
import pilav from './pilav.png';
import kavurma from './kavurma.png';
import phone from './phone.png';
import whatsapp from './whatsapp.png';
import Products from "../products/Products"; 
import lokmatatlısı from './lokmatatlısı.jpg';

const HomePage=()=>{

    const [news,setNews]=useState([])

    useEffect(()=>{
        fetch(`https://lokmacilar.pythonanywhere.com/products/news-home/`)
        .then(res=>res.json())
        .then(response=>{setNews(response);console.log(response)}) 
    },[])
    return(
<>
<div className="side-bar">
    <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
</div>
    <div className="container">
    </div>
    <h2 className="brand">Lokmacılar</h2>

    <div className="banner-text">
        <h2>Kalite Bizim İşimiz</h2>
        <p><a href="tel:+905426907712">Tıkla Ara... </a>
        </p>
    </div>
    <div className="products">
        <h2>Ürünler</h2>
        <div className="product">
            <h3>Lokma Döktürme</h3>
            <img className="product-image" src={lokmatatlısı} alt='product'/>
            <h2>Herşey Dahil</h2>
            <p>Fiyat İçin İletişime Geçebilirsiniz.</p>
        <div className="icons">
            <a href='tel:+905426907712'><img src={phone} alt='icon'/></a>
            <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
        </div>
    </div>
    <div className="product">
        <h3>Pide Dağıtma</h3>
        <img className="product-image" src={pide} alt='product'/>
        <h2>Herşey Dahil</h2>
            <p>Fiyat İçin İletişime Geçebilirsiniz.</p>
        <div className="icons">
            <a href='tel:+905426907712'><img src={phone} alt='icon'/></a>
            <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
        </div>
    </div>
    <div className="product">
        <h3>Tavuklu Pilav Dağıtma</h3>
        <img className="product-image" src={pilav} alt='product'/>
        <h2>Herşey Dahil</h2>
            <p>Fiyat İçin İletişime Geçebilirsiniz.</p>
        <div className="icons">
            <a href='tel:+905426907712'><img src={phone} alt='icon'/></a>
            <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
        </div>
    </div>
    <div className="product">
        <h3>Kavurmalı Pilav Dağıtma</h3>
        <img className="product-image" src={kavurma} alt='product'/>
        <h2>Herşey Dahil</h2>
            <p>Fiyat İçin İletişime Geçebilirsiniz.</p>
        <div className="icons">
            <a href='tel:+905426907712'><img src={phone} alt='icon'/></a>
            <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
        </div>
    </div>
</div>

    <div className="products-row">
        <Products/>
    </div>
    <div className="news-row">
    <div className='news-container'>
        <h2 className='news-header'>Haberler</h2>
        {news.map(news=>
            <div key={news.id} className='new'>
              <h2 className='new-caption'>{news.caption}</h2>
              <span className='date'>{news.date}</span>
              <div className='row'>
              <div className='col-1'>
                <img src={news.file} alt={news.caption}/>                     
              </div>
              <div className='col-2'>
                <p>{news.content}
                <a className='new-button' href={`/news/${news.id}/`}>Detay</a>              

                </p>     

              </div>
              </div>
            </div>
            )}
    </div>
     
    </div>
    <div className="footer">
        <h2>Lokmacılar Sitesi </h2>
        <p>Tüm hakları saklıdır</p>
        <p>Tüm Sorularınız İçin Bizimle İletişime geçebilirsiniz</p>
    </div>
</>
    )
}
export default HomePage


/* 

<i className="brand-text">Paylaşmak ve yardımlaşmak insanı yücelten en büyük erdemdir.</i>            
<div className="content">
<div className="content-image">
</div>
<div className="content-text">
<p>
  Lorem ipsum sit dolor amet.
</p>
</div>
</div>



<div className="products">
<h2>Ürünler</h2>
<div className="product">
    <h3>Lokma Döktürme</h3>
    <img className="product-image" src={lok} alt='product'/>
    <p>Herşey Dahil</p>
    <h2>1500 TL <span>'den Başlayan Fiyatlar</span></h2>
    <div className="icons">
        <a href='tel:+905426907712'><img src={phone} alt='icon'/></a>
        <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
    </div>
</div>
<div className="product">
<h3>Pide Dağıtma</h3>
<img className="product-image" src={pide} alt='product'/>
<p>Herşey Dahil</p>
<h2>1500 TL <span>'den Başlayan Fiyatlar</span></h2>
<div className="icons">
        <a href='tel:+905426907712'><img src={phone} alt='icon'/></a>
        <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
    </div>
</div>
<div className="product">
<h3>Tavuklu Pilav Dağıtma</h3>
<img className="product-image" src={pilav} alt='product'/>
<p>Herşey Dahil</p>
<h2>1500 TL <span>'den Başlayan Fiyatlar</span></h2>
<div className="icons">
        <a href='tel:+905426907712'><img src={phone} alt='icon'/></a>
        <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
    </div>
</div>
<div className="product">
<h3>Kavurmalı Pilav Dağıtma</h3>
<img className="product-image" src={kavurma} alt='product'/>
<p>Herşey Dahil</p>
<h2>1500 TL <span>'den Başlayan Fiyatlar</span></h2>
<div className="icons">
        <a href='tel:+905426907712'><img src={phone} alt='icon'/></a>
        <a href='https://wa.me/905426907712' target='_blank' rel="noopener noreferrer"><img src={whatsapp} alt='icon'/></a>
    </div>

</div>

</div>
<Products/>
<News/>
 */