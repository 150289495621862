import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import './productdetail.css';

const ProductDetail=()=>{
    const [product,setProduct]=useState([])
    const {id}=useParams()
    useEffect(()=>{
        fetch(`https://lokmacilar.pythonanywhere.com/products/product-detail/${id}/`)
        .then(res=>res.json())
        .then(response=>setProduct(response))
    },[id])
    return(
        <div className="product-container">
            <h2>{product.caption}</h2>
            <div className="image-box">
                <img src={product.image} alt={product.caption}/>
            </div>
            <div className="product-content">
                <h3>Detaylar</h3>
                <p>{product.persons} Kişilik</p>
                <p>{product.price} TL</p>
                <p>{product.description}</p>

            </div>            
        </div>
    )
}
export default ProductDetail