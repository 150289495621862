import React, { createContext,useState,useEffect } from "react";
import {useNavigate} from 'react-router-dom';


export const AuthContext = createContext()

export const AuthProvider =({ children }) => {
  const [token,setToken]=useState(null)
  const [username,setUsername]=useState(null)
  const [user,setUser]=useState(null)
  const [errors, setErrors] = useState(false);
  const [auth,setAuth]=useState(false)
  const navigate=useNavigate()
  const loginUser = async (e) => {
    e.preventDefault();
    const user = {
      username: e.target.username.value,
      password: e.target.password.value
    };

    const response = fetch('https://lokmacilar.pythonanywhere.com/users/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(user)
    })
    .then(res=>res.json())
    .then(data=>{
      if(data.token){
        setToken(data.token)
        setUsername(data.username)
        setUser(data.id)

        console.log(data)
        localStorage.setItem('username',data.username)
        localStorage.setItem('id',data.id)
        localStorage.setItem('token',data.token)
        localStorage.setItem('firstName',data.firstName)
        localStorage.setItem('lastName',data.lastName)

        navigate('/')
      }
      else if(!data.token){
        setErrors(true);
        console.log(data.error)
      }
    })
};


useEffect(()=>{
  
  fetch( `https://lokmacilar.pythonanywhere.com/users/user-connect/${localStorage.getItem('token')}`)
  .then(res=>res.json())
  .then(response=>{
    console.log(response)
    setUsername(response.username)
    setToken(response.token)
    setUsername(response.username)
    setUser(response.id)
    setAuth(true)

  })
}, [])

  const ContextData={
    loginUser : loginUser,
    username:username,
    token:token,
    user:user,
    auth:auth,
    errors:errors,
  }
  return (
       <AuthContext.Provider value={ContextData}>
        {children}
      </AuthContext.Provider>
    );
  }

