import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Navbar from './components/home/Navbar';
import SignUp from './components/users/SignUp';
import Login from './components/users/Login';
import HomePage from './components/home/HomePage';
import Products from './components/products/Products';
import News from './components/news/News';
import ProductDetail from './components/products/productDetail';
import NewsDetail from './components/news/NewsDetail';
import FranchiseCreate from './components/franchise/franchiseCreate';

function Page() {
  return (
    <div>
      <BrowserRouter>        
      <Navbar/>

        <Routes>
          <Route path="/" element={<HomePage />} />  
          <Route path="/users/signup/" element={<SignUp />} />  
          <Route path="/products/" element={<Products />} />  
          <Route path="/product-detail/:id/" element={<ProductDetail />} />  
          <Route path="/news/" element={<News />} />  
          <Route path="/news/:id/" element={<NewsDetail />} />  

          <Route path="/users/login/" element={<Login />} />  
          <Route path="/franchise" element={<FranchiseCreate />} />  

        </Routes>        
      </BrowserRouter>

  </div>



  )
}


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Page/>);





